export default {
    data() {
        return {
            checked_subscription: false,
            active_listener: undefined,
            unwatch: undefined,
        }
    },
    computed: {
        hasCheckedActive() {
            return this.$store.state.api.recurringDeliveries?.[0]
        },
    },
    mounted() {
        if (!this.hasCheckedActive) {
            if (this.$store.state.authentication.initialised) {
                this.$firebaseAuth.onAuthStateChanged(user => {
                    this.checkUser(this.$firebaseAuth.currentUser)
                })
            }
        } else {
            this.onFetchedActive()
        }
    },
    methods: {
        async checkUser(user) {
            if (user !== null && !this.$store.state.api.recurringDeliveries?.[0]) {
                await this.$store.dispatch("api/recurringDeliveries/get")

                this.onFetchedActive()

                this.checked_subscription = true
            } else if (window.sessionStorage.getItem("token") && !this.$store.state.api.recurringDeliveries?.[0]) {
                this.$axios.setToken(window.sessionStorage.getItem("token"), "Bearer")

                await this.$store.dispatch("api/recurringDeliveries/get")
                this.onFetchedActive()
                this.checked_subscription = true
            } else if (user === null) {
                this.noUserFound()
                this.checked_subscription = true
            } else {
                this.checked_subscription = true
            }
        },
        onFetchedActive() {
            if (this.$store.state.api.recurringDeliveries?.[0]?.subscription_status !== "active" && this.$store.state.mandateLink) {
                this.$router.push(this.localePath({ path: "/pet-overview" }))
            }
        },
        noUserFound() {},
    },
    destroyed() {
        if (this.active_listener) {
            this.active_listener()
        }
        if (this.unwatch) {
            this.unwatch()
        }
    },
}
